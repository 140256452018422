
.search_container {
    background-color: rgba(238, 238, 238, var(--search-bg-opacity));
}

.search_place_input {
    background-color: white;
    border-radius: 5px;
    padding: 5px 20px;
    width: 100%;
    text-align: left;
    color: #cccccc;
    margin-bottom: 10px !important;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.search_button {
    width: 100%;
    border-radius: 5px;
    text-align: center;
    margin-top: 30px;
    padding: 10px;
    background-color: lightsalmon;
    color: white;
    margin-bottom: 10px !important;
}

.search_time_input {
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    width: 23%;
    color: #cccccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    font-size: 18px;
    text-align: center;
}

.image_container {
    max-height: 180px;
}

